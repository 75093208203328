import React from "react"
import tw from "twin.macro"

import PrimaryCta from "@components/PrimaryCta"

const KeyPoints = ({
  points,
  largeIconMobile,
  wideCopy,
  iconSize = null,
  cardStyles,
}) => {
  return (
    <div
      css={[
        tw`flex flex-col w-full md:(flex-row justify-center gap-8 flex-wrap) xl:(gap-[30px])`,
      ]}
    >
      {points &&
        points.map((point, i) => {
          return (
            <div
              key={i}
              css={[
                tw`w-full mx-auto mb-7 last:mb-0 sm:w-auto md:(mx-[unset] mb-0 max-w-[26.7rem])`,
                points.length === 2 && tw`md:(grow max-w-[calc(50% - 2rem)])`,
              ]}
            >
              <div
                css={[
                  tw`flex flex-col justify-between relative w-full h-full bg-white rounded-[30px] text-center text-sm lg:py-8 xl:(min-h-[340px])`,
                  points.length === 2 && tw`md:w-full`,
                  !wideCopy && tw`px-[2.4rem] py-8`,
                  point.footnote && tw`justify-start`,
                  cardStyles && cardStyles,
                ]}
              >
                {/* Top content */}
                <div>
                  {point.title && (
                    <h3 tw="font-bold mb-3.5 text-xl">{point.title}</h3>
                  )}
                  {point.image && (
                    <div
                      css={[
                        tw`w-[60%] max-w-[128px] mx-auto overflow-hidden mb-[15px] mt-0 xl:(mb-6 max-w-[190px])`,
                        largeIconMobile && tw`w-[190px] max-w-[190px]`,
                        iconSize,
                      ]}
                    >
                      <img
                        tw="w-full block h-auto"
                        src={point.image}
                        alt={point.imageAlt || ""}
                      />
                    </div>
                  )}
                  <div
                    css={[
                      tw`text-base font-medium leading-[25.2px] xl:(text-xl)`,
                      point.textBold && tw`font-bold`,
                    ]}
                  >
                    {point.text || point.textBold}
                  </div>
                </div>

                {/* Bottom cta */}
                {point.cta && (
                  <div tw="mt-4 xl:mt-5">
                    <PrimaryCta
                      url={point.cta.url}
                      width={point.cta.width ? point.cta.width : tw`w-[280px]`}
                      height={point.cta.height && point.cta.height}
                      isExternal={point.isExternal}
                      noInterstitial={point.noInterstitial}
                    >
                      {point.cta.text}
                      {point.cta.icon && (
                        <img
                          tw="inline-block ml-2 w-3"
                          src={point.cta.icon}
                          alt=""
                        />
                      )}
                    </PrimaryCta>
                  </div>
                )}
                {point.footnote && (
                  <div tw="mt-4 xl:mt-5">
                    <p tw="text-xs font-medium leading-[15.6px] text-gray-600">
                      {point.footnote}
                    </p>
                  </div>
                )}
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default KeyPoints
